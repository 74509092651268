
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import Vue from "vue";
import * as moment from "moment";

export default {

  components: {
    Multiselect
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      facilitiesSnippet: snippets ?? {},
      facilitiesList: [],
      paginatedFacilitiesList: [],
      paginatedSearchedFacilitiesList: [],
      facilitiesPaginatedList: [],
      rolesList: [],
      facilitiesListUntouched: [],
      searchedData: [],
      searchKey: '',
      searchField: '',
      newEventName: '',
      addOrEditSpace: '',
      dialog: false,
      spaceDialog: false,
      eventDialog: false,
      editEventDialog: false,
      addEventsDialog: false,
      dialogDelete: false,
      deleteEventDialog: false,
      dialogUpgrade: false,
      submitted: false,
      eventsSubmitted: false,
      spaceSubmitted: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        description: '',
        shortDescription: '',
        address: '',
        qrCode: '',
        floors: [],
        photos: [],
        photoIds: [],
      },
      defaultItem: {
        id: '',
        name: '',
        description: '',
        shortDescription: '',
        address: '',
        qrCode: '',
        floors: [],
        photos: [],
        photoIds: [],
      },
      currentSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        mapPosition: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      oldSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        mapPosition: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      defaultSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        mapPosition: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      multiSelectRoles: [],
      sortBy: 'id',
      editedPrefix: '',
      sortDesc: true,
      errorKey: '',
      addEventsDialogErrorKey: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldFacility: {},
      editedEventItem: {},
      toDeleteEvent: {},
      addressesDataList: [],
      showSpaces: [],
      facilityData: [],
      defaultFloor: {
        id: 0,
        name: 'default',
        description: ''
      },
      eventsList: [],
      eventsNameList: [],
      oldEventsList: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
      emptyReportData: false,
      reportEndDate: '',
      reportStartDate: '',
      facilityId: '',
      menu: false,
      isAllowed: StorageService.getUserRoles() !== 'FacilityManager',
    }
  },

  computed: {
    maxDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },

    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },

    formTitle() {
      return this.editedIndex === -1 ? this.facilitiesSnippet.add_facility : this.facilitiesSnippet.edit_facility
    },

    headers() {
      return [
        { text: this.facilitiesSnippet.id, value: 'id', width: '20%', sortable: true },
        { text: this.facilitiesSnippet.name, value: 'name', width: '20%', sortable: true },
        {
          text: this.facilitiesSnippet.shortDescription,
          value: 'shortDescription',
          width: '20%',
          sortable: true
        },
        { text: this.facilitiesSnippet.address, value: 'address', width: '15%', sortable: true },
        { text: this.facilitiesSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    dialog( val ) {
      val || this.close()
    },

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    addEventsDialog( val ) {
      val || this.closeAddEventsDialog()
    },
    eventDialog( val ) {
      val || this.closeEventDialog()
    },

    editEventDialog( val ) {
      val || this.closeEditEventDialog()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    deleteEventDialog( val ) {
      val || this.closeDeleteEvent()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

    async searchKey( searchText ) {
      if ( searchText ) {
        this.search$.next( searchText );
        this.searchField = searchText
      } else {
        this.searchField = ''
        this.searchedData = [];
        this.search$.next( '' );
      }
    },

  },

  async mounted() {
    await this.getFacilitiesApi();

    this.loading = false;
    this.searchAction();
  },

  methods: {
    sortData() {
      const compare = ( a, b ) => {
        let result = 0;

        switch ( this.sortBy ) {
          case 'id':
          case 'name':
          case 'description':
          case 'shortDescription':
          case 'address':
            if ( !b[this.sortBy] ) {
              result = 1;
            }

            result = a[this.sortBy] > b[this.sortBy] ? 1 : -1;
            break;
        }

        return this.sortDesc ? -result : result;
      };

      this.searchedDataOriginal?.sort( compare );
      this.facilityData?.sort( compare );

      [ ...Object.values( this.searchedFacilitiesPaginatedList ?? [] ), ...Object.values( this.facilitiesPaginatedList ?? [] ) ].forEach( set => {
        if ( set instanceof Set ) {
          set.clear();
        } else if ( Array.isArray( set ) ) {
          set.length = 0;
        }
      } );

      this.searchedDataOriginal?.forEach( ( item, i ) => {
        const index = Math.floor( i / 10 );
        if ( !this.searchedFacilitiesPaginatedList[index + 1] ) {
          this.searchedFacilitiesPaginatedList[index + 1] = [];
        }
        this.searchedFacilitiesPaginatedList[index + 1].push( item )
      } );

      this.facilityData?.forEach( ( item, i ) => {
        const index = Math.floor( i / 10 );
        if ( !this.facilitiesPaginatedList[index + 1] ) {
          this.facilitiesPaginatedList[index + 1] = new Set();
        }
        this.facilitiesPaginatedList[index + 1].add( item )
      } );
    },

    async sortTable() {
      await Promise.resolve();
      this.sortData();
      await this.changePage( this.currentPage );
    },

    showOrHideSpaces( floorId ) {
      if ( this.showSpaces.includes( floorId ) ) {
        this.showSpaces = this.showSpaces.filter( item => item !== floorId )
      } else {
        this.showSpaces.push( floorId );
      }
    },

    searchAction() {
      this.search$ = new Subject();
      this.search$.pipe(
        map( text => text?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( term ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.getFacilitiesPaginated();
          return
        }

        let data = await this.searchText( term );
        await this.getSearchedFacilitiesPaginated( data );
        this.sortTable();
      } )
    },

    async getSearchedFacilitiesPaginated( data ) {
      let facilitiesCount = data.length;
      this.pagesCount = Math.ceil( facilitiesCount / 10 )

      this.searchedFacilitiesList = [];
      this.paginatedSearchedFacilitiesList.length = 0;
      this.searchedFacilitiesPaginatedList = {};

      await this.setSearchedFacilitiesToFacilitiesList( data );

      this.searchedDataOriginal = data;
      this.searchedData = this.getObjClone( this.searchedFacilitiesList )
    },

    async searchText( term ) {
      const facilities = await ApiRequestService.getRequest( `api/admin-facilities?searchText=${ term }` );
      for ( let i in facilities ) {
        facilities[i].menu = false;
      }
      return facilities
    },

    async changePage( page ) {
      this.currentPage = page;

      if ( this.searchField ) {
        if ( this.searchedDataOriginal?.length ) {
          await this.setSearchedFacilitiesToFacilitiesList( this.searchedDataOriginal );
        }
        return;
      }

      if ( this.facilityData?.length ) {
        await this.setFacilitiesToFacilitiesList( this.facilityData );
      }
    },


    showErrorDialog() {
      this.emptyReportData = true;
    },
    closeDialog(item) {
      item.menu = false;
      this.emptyReportData = false;
    },

    changeDate(item) {
      if ( this.reportEndDate < this.reportStartDate ) {
        this.reportEndDate = '';
        this.reportStartDate = '';
        return;
      }

      this.downloadReport( item, this.reportStartDate, this.reportEndDate ).then( () => {
        setTimeout(()=>{
          this.reportEndDate = '';
          this.reportStartDate = '';
          item.menu = false;
        },500)
      } )
    },

    async downloadReport( organization, startDate, endDate ) {
      const reportData = await ApiRequestService.postRequest( `api/admin-facilities/report/${ this.facilityId }`, {
        startDate,
        endDate
      } );
      if ( !reportData || !reportData.length ) {
        this.showErrorDialog();
        return;
      }

      this.reportHeader = Object.keys( reportData[0] );
      this.reportHeader = this.reportHeader.map( item => item.includes( '_' ) ? item.split( '_' ).join( ' ' ) : item );

      let csvData = [];
      reportData.forEach( data => {
        const rowValue = [];
        for ( let [ key, value ] of Object.entries( data ) ) {
          if ( key === 'Spent_credits' ) {
            rowValue.push( value * -1 );
          } else {
            rowValue.push( value );
          }
        }
        csvData.push( rowValue );
      } );
      const totalData = [];
      let totalAmount = 0;
      this.reportHeader.forEach( _ => {
        totalData.push( ' ' );
      } )
      reportData.forEach( item => {
        totalAmount += parseFloat( item.Spent_credits ) * -1 ?? 0;
      } )

      this.reportHeader.push( 'Total Spent Credits' )
      csvData.unshift( this.reportHeader );

      totalData.push( totalAmount )
      csvData.push( totalData );

      let csvContent = 'data:text/csv;charset=utf-8,';
      csvData.forEach( ( row ) => {
        const csvRow = row.join( ',' );
        csvContent += csvRow + '\r\n';
      } );

      // Create a temporary anchor element to download the CSV file
      const encodedUri = encodeURI( csvContent );
      const link = document.createElement( 'a' );
      link.setAttribute( 'href', encodedUri );
      link.setAttribute( 'download', organization.name + '-report-' + moment().days( -30 ).format( 'MM-DD-YYYY' ) + '-to-' + moment().format( 'MM-DD-YYYY' ) + '.csv' );
      document.body.appendChild( link );

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild( link );
    },

    openDownload(item){
      item.menu = !item.menu;
      this.facilityId = item.id;
    },


    async setSearchedFacilitiesToFacilitiesList( data ) {
      data.forEach( ( facility, index ) => {
        let existsIndex = this.paginatedSearchedFacilitiesList.findIndex( item => item.id === facility?.id );
        let facilityData = {
          id: facility?.id,
          name: facility?.name,
          description: facility?.description,
          shortDescription: facility?.shortDescription,
          address: facility?.address,
          qrCode: facility?.qrCode,
          floors: facility?.floors,
          menu: facility?.menu,
          photos: facility?.photos,
          photoIds: [],
          createDate: this.getToLocaleDate( facility?.createDate ),
        };
        if ( existsIndex !== -1 ) {
          let searchedFacilitiesPaginatedListClone = this.getObjClone( this.searchedFacilitiesPaginatedList[this.currentPage] )

          searchedFacilitiesPaginatedListClone.forEach( ( item, index ) => {
            if ( item?.id === facilityData?.id ) {
              this.searchedFacilitiesPaginatedList[this.currentPage][index] = facilityData
            }
          } );
          return;
        }

        if ( !this.searchedFacilitiesPaginatedList[this.currentPage] ) {
          this.searchedFacilitiesPaginatedList[this.currentPage] = [];
        }
        if ( this.searchedFacilitiesPaginatedList[this.currentPage].length < 10 ) {
          this.searchedFacilitiesPaginatedList[this.currentPage].push( facilityData );
        }
      } )

      if ( this.searchedFacilitiesPaginatedList[this.currentPage] ) {
        this.paginatedSearchedFacilitiesList = this.getObjClone( this.searchedFacilitiesPaginatedList[this.currentPage] )
      }
    },

    async getRolesApi() {
      let rolesData = await ApiRequestService.getRequest( 'api/roles' );
      await this.setRolesList( rolesData );
    },

    async getFacilitiesPaginated() {
      let facilitiesCount = this.facilityData.length;
      this.pagesCount = Math.ceil( this.facilityData.length / 10 );

      if ( this.pagesCount === 1 ) {
        this.currentPage = 1;
      }

      if ( facilitiesCount ) {
        this.setFacilitiesByPages( this.facilityData );
        await this.setFacilitiesToFacilitiesList( this.facilityData );
      }
    },

    async getFacilitiesApi() {
      this.facilityData = await ApiRequestService.getRequest( `api/admin-facilities` );
      if ( !this.facilityData?.length ) {
        return;
      }
      for ( let i in this.facilityData ) {
        this.facilityData[i].menu = false;
      }

      this.facilityData.map( facility => facility.floors.map( floor => floor.spaces.sort( ( a, b ) => a.mapPosition - b.mapPosition ) ) )
      await this.getFacilitiesPaginated();
      let facilitiesCount = this.facilityData?.length ?? 0;
      if ( facilitiesCount ) {
        await this.setFacilitiesToFacilitiesList( this.facilityData );
      }
      this.sortTable()
    },

    async getFacilityEventsApi( facilityId ) {
      //TODO: implement API
      this.chips = null//await ApiRequestService.getRequest( `api/facility-services/${ facilityId }` );
      this.oldChips = this.chips;

      if ( this.chips?.errorMessage ) {
        this.chips = [ { id: 0, name: 'TV' }, { id: 1, name: 'Food' } ];
        this.oldChips = this.getObjClone( this.chips )
      }

      this.chipsNameList = this.chips?.map( item => item.name );
    },

    async selectPerPageCount( event ) {
      if ( event === this.facilityData?.length ) {
        return
      }

      // this.facilityData = await ApiRequestService.getRequest( `api/facilities?offset=${ this.facilityData?.data?.length }&limit=${ event + 1 }` );
      // let page = Math.ceil( event / 10 );
      // this.facilityData = await ApiRequestService.getRequest( `api/facilities?page=${ page }` );

      if ( this.facilityData?.length ) {
        await this.setFacilitiesToFacilitiesList( this.facilityData );
      }
    },

    async setFacilitiesToFacilitiesList( facilityData ) {
      facilityData?.forEach( ( facility, index ) => {

        let existsIndex = this.facilitiesList.findIndex( item => item.id === facility?.id );
        let facilityData = {
          id: facility?.id,
          name: facility?.name,
          description: facility?.description,
          shortDescription: facility?.shortDescription,
          address: facility?.address,
          qrCode: facility?.qrCode,
          floors: facility?.floors,
          menu: facility?.menu,
          photos: facility?.photos,
          photoIds: []
          // createDate: facility?.createDate ? this.getToLocaleDate( facility?.createDate ) : null,
        };

        if ( existsIndex !== -1 ) {
          Vue.set( this.facilitiesList, existsIndex, facilityData )
          let facilitiesPaginatedListClone = [ ...this.facilitiesPaginatedList[this.currentPage].values() ]
          facilitiesPaginatedListClone.forEach( item => {
            if ( item?.id === facilityData?.id ) {
              this.facilitiesPaginatedList[this.currentPage].delete( item )
              this.facilitiesPaginatedList[this.currentPage].add( facilityData )
            }
          } );
          return;
        }

        Vue.set( this.facilitiesList, this.facilitiesList.length, facilityData )

        if ( !this.facilitiesPaginatedList[this.currentPage] ) {
          this.facilitiesPaginatedList[this.currentPage] = new Set();
        }
        if ( this.facilitiesPaginatedList[this.currentPage].size < 10 ) {
          this.facilitiesPaginatedList[this.currentPage].add( facilityData );
        }
      } )
      this.facilitiesListUntouched = this.facilitiesList
      if ( this.facilitiesPaginatedList[this.currentPage] ) {
        this.paginatedFacilitiesList = this.getObjClone( [ ...this.facilitiesPaginatedList[this.currentPage] ] )
      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    addFacilityToFacilityList( data ) {
      let newFacilityData = {
        id: data.id,
        name: data.name,
        description: data.description,
        shortDescription: data.shortDescription,
        address: data.address,
        qrCode: data.qrCode,
        floors: data.floors,
        menu: data.menu,
        photos: data.photos,
        // createDate: data.createDate ? this.getToLocaleDate( data.createDate ) : null
      };
      let existsIndex = this.facilityData?.findIndex( facility => facility.id === newFacilityData.id );

      if ( existsIndex !== -1 && existsIndex !== undefined ) {
        this.facilityData[existsIndex] = newFacilityData;
      } else {
        this.facilityData.push( newFacilityData );
        this.facilityData.totalElements++;
      }
      this.setFacilitiesByPages( this.facilityData );
      this.setFacilitiesToFacilitiesList( this.facilityData )
    },

    async getChangedFacilityData( oldFacility ) {
      const dataFormUpdate = {};

      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldFacility[key] === this.editedItem[key] ) {
          continue
        }

        dataFormUpdate[key] = this.editedItem[key]
      }

      if ( this.editedItem?.photoIds?.length ) {
        dataFormUpdate['photoIds'] = await this.uploadFacilityImages( this.editedItem );
      }

      return dataFormUpdate
    },


    async getChangedSpaceData( oldFacility ) {
      const dataFormUpdate = {};

      for ( let [ key, value ] of Object.entries( this.currentSpace ) ) {
        if ( oldFacility[key] === this.currentSpace[key] ) {
          continue
        }

        dataFormUpdate[key] = this.currentSpace[key]
      }

      if ( this.currentSpace?.photoIds?.length ) {
        if ( this.currentSpace?.photoIds?.length > 10 ) {
          this.errorKey = 'Maximum number of images allowed 10';
          return;
        }
        dataFormUpdate['photoIds'] = await this.uploadFacilitySpaceImages( this.currentSpace );
      }

      return dataFormUpdate
    },

    async updateFacilityRequest( oldFacility ) {
      let existsPhotosCount = this.getObjClone( this.facilitiesList[this.editedIndex] )?.photos?.length;
      if ( this.editedItem?.photoIds?.length + existsPhotosCount > 10 ) {
        this.errorKey = `Maximum number of images allowed 10.You have already ${ existsPhotosCount } images`;
        return;
      }

      const dataFormUpdate = await this.getChangedFacilityData( oldFacility );
      const updatedFacility = await ApiRequestService.updateRequest( `api/admin-facilities/${ this.editedItem.id }`, dataFormUpdate )

      if ( updatedFacility.errorMessage ) {
        this.close()
        return
      }

      if ( updatedFacility ) {
        this.facilitiesList = this.facilitiesList.filter( listItem => listItem.id !== updatedFacility.id )

        this.addFacilityToFacilityList( updatedFacility )
      }

      this.close()
    },

    dataIsValid() {
      const nameIsValid = this.facilityIsValid( this.editedItem?.name?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    spaceDataIsValid() {
      const nameIsValid = this.facilityIsValid( this.currentSpace?.name?.trim() )
      const qrCodeIsValid = this.facilityIsValid( this.currentSpace?.qrCode?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }
      if ( qrCodeIsValid !== true ) {
        isNotValid.push( qrCodeIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    facilityIsValid( v ) {
      return ( v && v.length > 0 ) || this.facilitiesSnippet.required
    },

    async editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedFacilitiesList : this.paginatedFacilitiesList;
      let list = this.searchField ? this.searchedFacilitiesList : this.facilitiesList;
      this.editedIndex = paginatedList.findIndex( fc => fc.id === item.id );
      this.listEditedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );

      await this.getFacilityEventsApi( this.editedItem.id );

      this.dialog = true
      this.disableKeyField = true
      this.oldFacility = this.getObjClone( this.editedItem )
    },

    editEvent( item ) {
      event.stopPropagation();
      this.editEventDialog = true;
      this.editedEventItem = this.getObjClone( this.eventsList ).find( event => event?.name === item );
    },

    addEvent() {
      if ( !this.newEventName ) {
        this.addEventsDialogErrorKey = 'Wrong event';
        return;
      }
      if ( this.eventsList?.findIndex( event => event.name.toLowerCase() === this.newEventName.trim().toLowerCase() ) !== -1 ) {
        this.addEventsDialogErrorKey = 'This event exists';
        return;
      }
      this.addEventsDialogErrorKey = '';
      //TODO: send add event api;
      this.eventsList.push( {
        id: this.eventsList?.length,
        name: this.newEventName
      } )
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      this.newEventName = '';
    },

    deleteSelectedEvent( item ) {
      event.stopPropagation();
      this.chips = this.chips.filter( chip => chip.name !== item );
      this.getEditedEventsNames();
    },
    deleteEvent( item ) {
      event.stopPropagation();
      this.toDeleteEvent = item;
      this.deleteEventDialog = true;
    },

    addNewFloor() {
      let index = this.editedItem.floors.at( -1 )?.id ? this.editedItem.floors.at( -1 )?.id + 1 : 1;
      let newFloor = this.getObjClone( this.defaultFloor );
      newFloor.id = index;
      this.editedItem.floors.push( newFloor )
    },

    removeFloor( floor ) {
      this.editedItem.floors = this.editedItem.floors.filter( item => item.id !== floor.id );
    },


    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedFacilitiesList : this.paginatedFacilitiesList;
      this.editedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },

    upgradeItem( item ) {
      this.editedIndex = this.facilitiesList.indexOf( item )
      this.dialogUpgrade = true
    },


    async deleteItemConfirm() {
      let item = await ApiRequestService.deleteRequest( `api/admin-facilities/${ this.editedItem.id }` );
      if ( item ) {
        await this.getFacilitiesPaginated();
        await this.setFacilitiesByPages( this.facilityData );

        this.facilityData = this.facilityData?.filter( item => item.id !== this.editedItem.id );
        this.facilitiesList = this.facilitiesList?.filter( item => item.id !== this.editedItem.id );
        this.paginatedSearchedFacilitiesList = this.paginatedSearchedFacilitiesList?.filter( item => item.id !== this.editedItem.id );
        this.paginatedFacilitiesList = this.paginatedFacilitiesList?.filter( item => item.id !== this.editedItem.id );
        this.searchedFacilitiesList = this.searchedFacilitiesList?.filter( item => item.id !== this.editedItem.id );
      }

      this.closeDelete()
    },
    async deleteEventConfirm() {
      this.eventsList = this.eventsList?.filter( chip => chip.name !== this.toDeleteEvent );
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      //TODO: send delete event request

      this.closeDeleteEvent()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    openSpaceDialog( floorId, space ) {
      this.spaceDialog = true;
      this.addOrEditSpace = 'edit';
      this.currentSpace = space;
      this.currentSpace.floorId = floorId;
      this.oldSpace = this.getObjClone( space );
      this.oldSpace.floorId = floorId

      for ( let [ key, value ] of Object.entries( space ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue );
          }
        } else {
          this.$set( this.currentSpace, key, value );
        }
      }
    },

    close() {
      this.dialog = false;
      this.submitted = false;
      this.errorKey = '';
      // this.$refs.fileupload.reset();

      /**
       * if action is edit, set to form old data
       */
      let data;
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.oldFacility );
      } else {
        data = this.getObjClone( this.defaultItem );
      }

      for ( let [ key, value ] of Object.entries( data ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue );
          }
        } else {
          this.$set( this.editedItem, key, value );
        }
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    closeEventDialog( isCanceled ) {
      this.eventDialog = false;
      this.eventsSubmitted = true;

      if ( isCanceled ) {
        this.chips = this.getObjClone( this.oldChips );
        this.eventsList = this.getObjClone( this.oldEventsList );
        this.getEditedEventsNames();
      }
    },

    closeAddEventsDialog() {
      this.addEventsDialog = false;
      this.eventsSubmitted = true;
      this.addEventsDialogErrorKey = '';
    },

    closeDelete() {
      this.dialogDelete = false;
      this.submitted = false;
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },
    closeDeleteEvent() {
      this.deleteEventDialog = false;
    },

    closeUpgrade() {
      this.dialogUpgrade = false;
      this.submitted = false;
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    setNewFacilityToFacilitiesList( facilityData ) {
      if ( facilityData ) {

        this.addFacilityToFacilityList( facilityData );

        this.close();
      } else if ( facilityData.errorMessage ) {
        console.log( 'facilityData.errorMessage', facilityData.errorMessage )
      }
    },

    generateRandomUniqueString( len ) {
      let text = '';
      for ( let i = 0; i < len; i++ ) {
        text += Math.random().toString( 36 ).slice( 2 ) + Date.now()
      }

      return text
    },

    async saveFacility() {
      this.editedItem.qrCode = this.generateRandomUniqueString( 2 );

      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        return;
      }

      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( value ) {
          dataToCreate[key] = value;
        }
      }

      if ( dataToCreate?.photoIds?.length > 10 ) {
        this.errorKey = 'Maximum number of images allowed 10';
        return;
      }

      dataToCreate['photoIds'] = await this.uploadFacilityImages( dataToCreate );

      const facilityData = await ApiRequestService.postRequest( 'api/admin-facilities', dataToCreate );
      if ( facilityData?.errorMessage ) {
        this.errorKey = facilityData?.errorMessage?.split( ',' );
        return;
      }
      if ( !facilityData ) {
        return;
      }

      this.setNewFacilityToFacilitiesList( facilityData );
    },

    openDetails( facility ) {
      if ( facility && facility.id ) {
        this.$router.push( `facility-details/${ facility.id }` )
      }
    },

    async uploadFacilityImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/facility-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },
    async uploadFacilitySpaceImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/facility-photos/facility-space-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },

    async updateFacility() {
      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        return;
      }

      await this.updateFacilityRequest( this.oldFacility );
    },

    async save() {
      this.submitted = true;

      // save or edit
      if ( this.editedIndex === -1 ) {
        await this.saveFacility();
      } else {
        await this.updateFacility();
      }
    },

    getEditedEventsNames() {
      this.chipsNameList = this.chips?.map( item => item.name );
      this.eventsNameList = this.eventsList?.map( item => item.name );
    },

    saveEditedEventItem() {
      let editedEventItemIndex = this.eventsList?.findIndex( item => item.id === this.editedEventItem.id );
      this.$set( this.eventsList, editedEventItemIndex, this.editedEventItem );

      this.chipsNameList.forEach( item => {
        let selectedEditedEventItemIndex = this.chips.findIndex( chip => chip.name === item );

        let editedEventItemIndex = this.oldEventsList.findIndex( chip => chip.name === item );
        let editedEventItem = this.eventsList[editedEventItemIndex];

        if ( selectedEditedEventItemIndex !== -1 ) {
          this.$set( this.chips, selectedEditedEventItemIndex, {
            id: editedEventItem.id,
            name: editedEventItem.name
          } );
        } else {
          if ( editedEventItem ) {
            this.chips.push( {
              id: editedEventItem.id,
              name: editedEventItem.name,
            } )
          }
        }
      } )

      this.getEditedEventsNames();

      this.eventsListHasBeenEdited = true;
      this.closeEditEventDialog();
    },

    saveEvents() {
      this.oldChips = this.getObjClone( this.chips );
      if ( this.eventsListHasBeenEdited ) {
        //TODO: send events list update request
      }
      //TODO: end current facility events list update request
      this.closeEventDialog();
    },

    async saveSpace() {
      this.spaceSubmitted = true;
      let isValid = this.spaceDataIsValid();
      if ( isValid !== true ) {
        return;
      }
      this.currentSpace.services = this.chipsNameList;

      let dataToCreate = await this.getChangedSpaceData( this.oldSpace );

      let spaceData = {};
      if ( this.addOrEditSpace === 'add' ) {
        spaceData = await ApiRequestService.postRequest( 'api/admin-facility-spaces/' + this.editedItem.id, dataToCreate );
      } else if ( this.addOrEditSpace === 'edit' ) {
        spaceData = await ApiRequestService.patchRequest( 'api/admin-facility-spaces/' + this.currentSpace.id, dataToCreate );
      }
      if ( !spaceData ) {
        return;
      }
      if ( spaceData?.errorMessage ) {
        this.errorKey = spaceData?.errorMessage?.split( ',' );
        return;
      }

      this.closeSpaceDialog();
    },

    setPhotos( files ) {
      if ( files ) {
        this.editedItem.photoIds = files;
      }
    },


    setFacilitiesByPages( data ) {
      let currentItems = this.getObjClone( data );

      for ( let pageIndex = 1; pageIndex <= this.pagesCount; pageIndex++ ) {
        for ( let itemIndex = 0; itemIndex < 10; itemIndex++ ) {
          if ( !currentItems[itemIndex] ) {
            break;
          }

          let existsIndex = this.facilitiesList.findIndex( item => item.id === currentItems[itemIndex]?.id );
          let facilityData = {
            id: currentItems[itemIndex]?.id,
            name: currentItems[itemIndex]?.name,
            description: currentItems[itemIndex]?.description,
            shortDescription: currentItems[itemIndex]?.shortDescription,
            address: currentItems[itemIndex]?.address,
            qrCode: currentItems[itemIndex]?.qrCode,
            floors: currentItems[itemIndex]?.floors,
            menu: currentItems[itemIndex]?.menu,
            photos: currentItems[itemIndex]?.photos,
            photoIds: []
            // createDate: facility?.createDate ? this.getToLocaleDate( facility?.createDate ) : null,
          };

          if ( existsIndex !== -1 ) {
            Vue.set( this.facilitiesList, existsIndex, facilityData )
            let facilitiesPaginatedListClone = []
            if ( this.facilitiesPaginatedList[pageIndex] ) {
              facilitiesPaginatedListClone = [ ...this.facilitiesPaginatedList[pageIndex].values() ]
            }
            facilitiesPaginatedListClone.forEach( item => {
              if ( item?.id === facilityData?.id ) {
                this.facilitiesPaginatedList[pageIndex].delete( item )
                this.facilitiesPaginatedList[pageIndex].add( facilityData )
              }
            } );
            continue;
          }

          Vue.set( this.facilitiesList, this.facilitiesList.length, facilityData )

          if ( !this.facilitiesPaginatedList[pageIndex] ) {
            this.facilitiesPaginatedList[pageIndex] = new Set();
          }
          if ( this.facilitiesPaginatedList[pageIndex].size < 10 ) {
            this.facilitiesPaginatedList[pageIndex].add( facilityData );
          }
        }
        currentItems = currentItems.splice( pageIndex * 10 );
      }

      this.facilitiesListUntouched = this.facilitiesList

      if ( this.facilitiesPaginatedList[this.currentPage] ) {
        this.paginatedFacilitiesList = this.getObjClone( [ ...this.facilitiesPaginatedList[this.currentPage] ] )
      }
    }
  }
}

